/*

 Custom colors for Ogochi organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #181F18;
$organization_primary_focus: #9d9d9d;
$organization_primary_disabled: #9d9d9d;

$organization_program_default: #181F18;

$organization_secondary: #181F18;
$organization_secondary_focus: #181F18;
$organization_secondary_transparent: #9d9d9d;
$organization_secundary_border: #ffffff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #9d9d9d;

$organization_secundary_button: #181F18;
$organization_secundary_button_focus: #181F18;
